<template>
  <div>
    <h2>{{ $t('views.orders.changetracking.title') }}</h2>

    <entity-changetracking-table :entityBy="eParent" :storeModule="storeModule"/>
  </div>
</template>

<script>
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'
import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityChangetrackingTable from '@/components/entity/ChangetrackingTable'

export default {
  name: 'Orders.ChangeTracking',
  mixins: [MixinEntityBase],
  components: {
    EntityChangetrackingTable
  },
  data () {
    return {
      storeModule: STOREMODULE_ENDPOINTMODULE_MAP.order
    }
  }
}
</script>

<style lang="scss"></style>
